import { apiHttpClient } from '@/configs/httpClient/api.httpClient';
import { environments } from '@/const/environments';
import { removeLocalItem, setLocalItem } from '@/utils/localStorage.util';
import type { LoginBody, LoginByQRResponse, LoginResponse } from './types';

export async function login(requestBody: LoginBody) {
    const response = await apiHttpClient.post<LoginResponse>('auth/login', {
        password: requestBody.password,
        identifier: requestBody.identifier,
        deviceCode: requestBody?.deviceCode,
    });

    if (response.data?.accessToken && response.data?.refreshToken) {
        setLocalItem('accessToken', response.data.accessToken);
        setLocalItem('refreshToken', response.data.refreshToken);
    }

    return response.data;
}

export async function logout() {
    removeLocalItem('accessToken');
    removeLocalItem('refreshToken');
}

export async function loginByQRInit() {
    const response =
        await apiHttpClient.post<LoginByQRResponse>('auth/initiate');

    return response.data;
}

export async function loginByGoogle() {
    const baseUrl = environments.API_BASE_URL;
    const oauthPath = '/oauth2/authorization/google';
    const fullUrl = new URL(oauthPath, baseUrl).toString();

    window.location.href = fullUrl;
}
