import type { CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { logout } from '@/services/auth';
import { getLocalItem, setLocalItem } from '@/utils/localStorage.util';

function getUserToken() {
    const token = getLocalItem<string>('accessToken');
    if (token) {
        return `Bearer ${token}`;
    }
    return null;
}

interface CreateAxiosArguments {
    baseURL: string;
    timeout: number;
}

export function createAxios({ baseURL, timeout }: CreateAxiosArguments) {
    const axiosConfig: CreateAxiosDefaults = {
        baseURL,
        timeout,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const axiosInstance = axios.create(axiosConfig);

    axiosInstance.interceptors.request.use((request) => {
        const token = getUserToken();
        if (token) {
            request.headers.Authorization = token;
        }
        return request;
    });

    let isRefreshing = false;
    let failedQueue: {
        resolve: (token: string) => void;
        reject: (error: any) => void;
    }[] = [];

    const processQueue = (error: any = null, token: string | null = null) => {
        failedQueue.forEach((promise) => {
            if (error) {
                promise.reject(error);
            } else if (token) {
                promise.resolve(token);
            }
        });
        failedQueue = [];
    };

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if (error.response?.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            originalRequest.headers.Authorization = `Bearer ${token}`;
                            return axiosInstance(originalRequest);
                        })
                        .catch((responseError) => {
                            throw responseError;
                        });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                try {
                    const refreshTokenResponse = await axios.post(
                        `${baseURL}/auth/refresh-token`,
                        {
                            refreshToken: getLocalItem('refreshToken'),
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        },
                    );
                    const { accessToken, refreshToken } =
                        refreshTokenResponse.data;

                    setLocalItem('accessToken', accessToken);
                    setLocalItem('refreshToken', refreshToken);

                    processQueue(null, accessToken);
                    originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    // Logout user
                    processQueue(refreshError, null);
                    await logout();
                    window.location.href = '/auth/login';
                } finally {
                    isRefreshing = false;
                }
            }

            throw error;
        },
    );

    return axiosInstance;
}
