import { createContext } from 'react';

export interface PlayListItem {
    title: string;
    link: string;
    episodeNumber: number;
    metaData: {
        skipToTime?: number;
    };
}

export type MediaType = 'SERIES' | 'MOVIE';

export interface SeriesProps {
    type: 'SERIES';
    cover: string;
    playList: PlayListItem[];
    activeEpisode: number;
    mediaLinkId: number;
    previousSeenTime?: number;
    metaData: {
        skipToTime?: number;
    };
}

export interface MovieProps {
    type: 'MOVIE';
    cover: string;
    title: string;
    link: string;
    mediaLinkId: number;
    previousSeenTime?: number;
    metaData: {
        skipToTime?: number;
    };
}

export type PlayProps = SeriesProps | MovieProps;

interface PlayContextType {
    playData: PlayProps;
    updatePlayData: (data: Partial<PlayProps>) => void;
    isMovie: (data: PlayProps) => data is MovieProps;
    isSeries: (data: PlayProps) => data is SeriesProps;
}

const defaultPlayData: PlayProps = {
    type: 'MOVIE',
    title: '',
    link: '',
    cover: '',
    mediaLinkId: 0,
    previousSeenTime: undefined,
    metaData: {
        skipToTime: undefined,
    },
};

export const PlayContext = createContext<PlayContextType>({
    playData: defaultPlayData,
    updatePlayData: () => void 0,
    isMovie: (data) => data.type === 'MOVIE',
    isSeries: (data) => data.type === 'SERIES',
});
